import { styled } from '@linaria/react';

export const StyledPage = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing-2xl) var(--spacing-3xl);
  border-bottom: 1px solid var(--border-hairline);
`;

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--inverse-surface);
  width: 100%;
  height: 56px;
  flex: 0 0 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-md);
`;

export const HeaderLogo = styled.img`
  height: 1.5rem;

  body[data-mobile='true'] & {
    height: 1rem;
    position: absolute;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: var(--spacing-4xl);
  gap: var(--spacing-5xl);
  height: 100%;
  overflow: hidden;
  position: relative;

  body[data-mobile='true'] & {
    gap: var(--spacing-sm);
    padding: var(--spacing-xl) var(--spacing-md) var(--spacing-md) var(--spacing-md);
    overflow-y: auto;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  text-align: center;

  body[data-mobile='true'] & {
    text-align: left;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);

  body[data-mobile='true'] & {
    flex-direction: column;
    gap: var(--spacing-md);
  }
`;

export const Buttons = styled.div`
  width: 41.5rem;
  position: absolute;
  top: var(--spacing-2xl);

  display: flex;
  justify-content: space-between;
`;
